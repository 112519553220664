<template>
  <div class="product-center">
    <div class="center-body">
      <div class="center-list">
        <ul class="first">
          <span>Classification:</span>
          <li
            class="product-classify"
            v-for="(Son, foindex) in glist"
            :class="{ checked: n === foindex }"
            :key="foindex"
            v-on:click="choose(foindex)"
          >
            <a>
              {{ Son.name }}
            </a>
          </li>
        </ul>
        <ul class="two">
          <span>band:</span>
          <li
            class="product-classify"
            v-for="(tSon, toindex) in list"
            :class="{ checked: nt === toindex }"
            :key="toindex"
            v-on:click="Tchoose(toindex)"
          >
            <a>
              {{ tSon.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="proCenter">
      <iframe
        style="position：absolute"
        id="particul"
        frameborder="0"
        width="100%"
        height="100%"
        scrolling="no"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  // 接收数组对象
  props: {
    // items: Array,
    powers: Array,
  },
  data() {
    return {
      list: [], //产品数据
      glist: [], //分类数据
      categoryId: "", //分类id
      n: 0, //分类点击匹配
      nt: 0, //频段点击匹配
      proId: null, //频段产品id
      aa: "",
    };
  },
  created() {
    this.categoryId = this.$route.params.id;
    if (
      this.$route.params.nId != undefined ||
      this.$route.params.nId != null ||
      this.$route.params.nId != ""
    ) {
      this.proId = this.$route.params.nId;
    } else {
      this.n = 0;
      this.nt = 0;
    }
    if (this.categoryId == 2) {
      this.n = 0;
    } else if (this.categoryId == 3) {
      this.n = 1;
    } else if (this.categoryId == 4) {
      this.n = 2;
    }
    this.getList(this.n, this.nt); //生命周期创建时调用axios函数
  },

  methods: {
    //请求分类数据
    async getList(n) {
      const ImageInfo = await this.axios.get("/api/product/category/listAll");
      this.glist = ImageInfo.data.list;
      this.categoryId = this.glist[n].id;
      this.getPCenList(this.categoryId, this.nt);
    },
    //请求数据
    getPCenList(categoryId) {///api/product/list
      this.axios
        .get("/api/product/listMin", {
          params: { categoryId: categoryId },
        })
        .then((res) => {
          this.list = res.data.page.list;
          if (this.nt == null || this.nt == undefined || this.nt == "") {
            if (
              this.$route.params.nId != null ||
              this.$route.params.nId != undefined ||
              this.$route.params.nId != ""
            ) {
              for (let i = 0; i < this.list.length; i++) {
                if (this.$route.params.nId === this.list[i].id) {
                  this.nt = i;
                  this.$route.params.nId = "";
                }
              }
            } else {
              this.nt = 0;
            }
          }
          this.proId = this.list[this.nt].id;
          this.getAbout(this.proId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //分类点击按钮激活，以及请求数据
    choose(foindex) {
      if (foindex != this.n) {
        this.n = foindex;
      }
      this.Tchoose(0);
    },
    //频段点击按钮激活，以及请求数据
    Tchoose(toindex) {
      if (toindex != this.nt) {
        this.nt = toindex;
      }
      this.getList(this.n, this.nt);
    },
    getAbout() {
      this.axios
        .get("/api/product/info/" + this.proId)
        .then((re) => {
          this.aa = re.data.info.particulars;
          document.getElementById(
            "particul"
          ).contentWindow.document.body.innerText = "";
          this.getHeig();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getHeig() {
      //容器自适应高度
      document.getElementById("particul").contentWindow.document.write(this.aa);
      var iframe = document.getElementById("particul");
      iframe.height =
        iframe.contentWindow.document.documentElement.scrollHeight;
    },
  },
};
</script>

<style scoped>
.product-center {
  width: 1286px;
  /* margin-top: 750px; */
  margin: 700px auto;
  position: absloute;
  /* height: 700px; */
  padding-bottom: 20px;
  margin-bottom: 80px;
}
.center-body {
  width: 1286px;
  padding-top: 30px;
}

.product-center .center-field {
  font-size: 14px;
  padding-top: 20px;
  color: rgb(175, 175, 175);
}
.product-center .center-list {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 1286px;
}
.product-classify {
  display: inline-block;
  margin-left: 20px;
  margin-bottom: -10px;
}
.first a {
  border: 1px solid #ddd;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #5d6062;
  cursor: pointer;
}
.first a:hover {
  background-color: #208bcf;
}

.first .checked a {
  color: #fff;
  cursor: default;
  background-color: #208bcf;
  border-color: #271b5c;
}
.two {
  margin-top: 20px;
}
.two a {
  border: 1px solid #ddd;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #5d6062;
  cursor: pointer;
  margin-right: 0px;
}
.two a:hover {
  background-color: #208bcf;
}

.two .checked a {
  color: #fff;
  cursor: default;
  background-color: #208bcf;
  border-color: #271b5c;
}
</style>