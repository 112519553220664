<template>
  <div class="product-pageImg" :style="productpageImg">
    <div class="pageImg-info">
      <div class="chinese">
        <!-- <p class="font1">产品中心</p>
        <p class="font2">智能制造创新发展</p> -->
        <!-- <input type="text" v-model="searchString" placeholder="查找你想要的产品" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchString: "",
      list: [],
      listimg: [],
      coverImg: "",
      productpageImg: {
        backgroundImage: "",
      },
    };
  },
  created() {
    this.getHpageImgList(); //生命周期创建时调用axios函数
  },
  methods: {
    getHpageImgList() {
      this.axios;
      var id = this.$route.params.id;
      console.log("id:" + id);
      this.axios
        .get("/api/nav/menu/info/" + id)
        .then((re) => {
          this.coverImg = re.data.info.coverImg;
          this.productpageImg.backgroundImage =
            "url(" + this.axios.defaults.baseURL + this.coverImg + ")";
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.product-pageImg {
  width: 100%;
  position: absolute;
  top: 150px;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 100;
  height: 700px;
  /* background-image: url("../../assets/img/product (0).png"); */
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
  /* text-align: left; */
  overflow: hidden;
}
.product-pageImg .pageImg-info {
  width: 60%;
  /* max-width: 1440px; */
  margin: 0 auto;
  margin-top: 208px;
  /* margin-left: ; */
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* Khtml内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}

.product-pageImg .chinese {
  color: rgb(253, 253, 253);

  float: left;
  margin-left: 40px;
  margin-top: 3px;
}
.font1 {
  font-size: 58px;
  font-weight: 600;
}
.font2 {
  font-size: 46px;
  font-weight: 600;
  padding-top: 30px;
}
.chinese input {
  background: rgba(255, 255, 255, 0.93) no-repeat 13px 10px;
  background-image: url("../../assets/img/search.png");
  background-size: 38px 38px;
  border: none;
  width: 853px;
  line-height: 29px;
  padding-top: 14px;
  padding-bottom: 11px;
  padding-left: 22px;
  border-radius: 3px;
  box-shadow: 0 2px 8px #c4c4c4 inset;
  text-align: left;
  font-size: 15px;
  font-family: inherit;
  color: #e3e3e4;
  outline: none;
  text-indent: 40px;
  margin-top: 15px;
  margin-left: -6px;
}
</style>