<template>
  <div class="product">
    <product-img></product-img>
    <product-center></product-center>
  </div>
</template>
<script>
import ProductImg from "@/components/Product/pageImg.vue";
import ProductCenter from "@/components/Product/center.vue";
export default {
  name: "Product",
  data() {
    return {};
  },
  components: {
    ProductImg,
    ProductCenter,
  },
};
</script>

<style scoped>
.product {
  width: 100%;
}
</style>